<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="appLogo" alt="logo" contain class="me-3" />
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Forgot Password? 🔒</p>
          <p class="mb-2">Enter your email and we'll send you instructions to reset your password</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="someone@example.com"
              hide-details="auto"
              class="mb-4"
              :rules="[validators.required, validators.emailValidator]"
            />

            <v-alert v-if="errors && errors.length > 0" class="mt-4" color="error" text>
              <p class="font-weight-semibold mb-1">Messages</p>
              <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
                {{ error }}
              </p>
            </v-alert>

            <v-btn block color="primary" @click="submit">
              <span v-if="loading === false">Send reset link</span>
              <span v-else>
                <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
                <span>Sending...</span>
              </span>
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-center align-center">
          <router-link :to="{ name: 'auth-login-v1' }" class="d-flex align-center text-sm">
            <v-icon size="24" color="primary">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back to login</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import themeConfig from '@themeConfig'
import { required, emailValidator } from '@core/utils/validation'
import { ref, unref } from '@vue/composition-api'
//store
import store from '@/store'

export default {
  setup() {
    const loading = ref(false)
    const email = ref('')
    const form = ref('')
    const errors = ref([])

    const submit = async () => {
      const check = form.value.validate()
      if (!check) {
        return
      }

      const data = {
        email: unref(email),
      }

      loading.value = true
      await store
        .dispatch('auth/forgotPassword', data)
        .then(async resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              await store.dispatch('snackbar/set', {
                status: true,
                text: resp.data.message,
                color: 'primary',
              })

              errors.value = null
              form.value.reset()
            }
          }
        })
        .catch(error => {
          errors.value = error.response.data.errors
          console.log(errors.value)
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,
      email,
      form,
      errors,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },

      submit,

      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
